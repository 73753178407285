/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../css/bootstrap.min.css');
require('../css/demo.min.css');
require('../css/font-awesome.min.css');
require('../css/invoice.min.css');
require('../css/lockscreen.min.css');
require('../css/smartadmin-production-plugins.min.css');
require('../css/smartadmin-production.min.css');
require('../css/smartadmin-rtl.min.css');
require('../css/smartadmin-skins.min.css');
require('../css/your_style.css');
require('../css/login.css');






// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// var $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
